/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-rain-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.158 12.025a.5.5 0 01.316.633l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.317m3 0a.5.5 0 01.316.633l-1 3a.5.5 0 11-.948-.316l1-3a.5.5 0 01.632-.317m3 0a.5.5 0 01.316.633l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.317m3 0a.5.5 0 01.316.633l-1 3a.5.5 0 11-.948-.316l1-3a.5.5 0 01.632-.317m.247-6.998a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 11H13a3 3 0 00.405-5.973"/>',
    },
});
